@font-face {
  font-family: "VELOSans";
  src: url("../assets/fonts/VELOSans-Regular.otf") format("opentype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "VELOSans";
  src: url("../assets/fonts/VELOSans-Bold.otf") format("opentype");
  font-weight: bold;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  font-family: "VELOSans";
  scroll-behavior: smooth;
}

body {
  font-family: "VELOSans";
  min-height: 100vh;
  font-size: 20px;
  line-height: 1.2;

  @include HD {
    font-size: 28px;
  }
}

body {
  overflow-y: scroll !important;
}

h1 {
  font-size: 32px;
  color: #041E42;

  @include HD {
    font-size: 42px;
  }
}

h1, h2, h3 {
  color: #041e42;
}

button {
  font-family: "VELOSans";
}

.global-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 52px;
  justify-content: space-between;
  height: 100vh;
}

.container {
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
}
.container--center {
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;

  @include HD {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .global-wrapper {
    padding-top: 88px;
  }
}

.blue-text {
  color: #00aed6;
}