.velo-page {
  font-family: "VELOSans";
  width: 100%;

  &__images {
    margin-top: -4vw;
    @include vHD{
      margin-top: -1.6vw;
    }
  }

  h2,
  p {
    margin: 20px 0;
  }
  h3,
  h2,
  h1 {
    line-height: 1.2;
    margin-top: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    @include vHD {
      display: none;
    }
  }

  &__image--destkop {
    display: none;
    @include vHD {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.velo-intro {
  background: #041e42;
  color: #fff;
  padding: 30px 0;
  width: 100%;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;

    @include HD {
      grid-template-columns: minmax(700px, 1fr) 1fr;
      grid-template-rows: 200px 1fr;
    }
  }

  &__image {
    display: block;
    height: auto;
    width: 250px;
    margin: 0 auto;
    padding: 1rem 0;

    @include HD {
      grid-row: 1 / span 3;
      grid-column: 2 / span 1;
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    img:nth-of-type(2) {
      display: block;
      width: 250px;
      height: 283px;
      @include vHD {
        display: none;
      }
    }

    img:nth-of-type(1) {
      display: none;
      @include vHD {
        display: block;
      }
    }

    img {
      width: 100%;
      height: auto;

      @include HD {
        height: auto;
        width: 90%;
        margin-left: auto;
        transform: translate(0,-100px);
      }
    }
    
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 28px;
    font-weight: bold;
    max-width: 400px;
    color: #fff;

    &--special {
      color: #00aed6;
      display: block;
    }

    @include HD {
      font-size: 52px;
      max-width: 770px;
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 28px;
    color: #00aed6;

    @include HD {
      font-size: 52px;
    }
  }

  &__desc {
    font-size: 19px;

    @include HD {
      font-size: 30px;
      line-height: 35px;
    }
  }

  &__disclaimer {
    font-size: 14px;

    span {
      display: block;

      @include HD {
        display: inline;
      }
    }

    @include HD {
      font-size: 15px;
      line-height: -30px;
      width: 115%;
    }
  }

  &__logo {
    margin: 2rem 0;
    width: 131.3px;
    height: 114.9px;
    align-self: center;
    display: block;
    margin-left: auto;
    margin-right: auto;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    @include HD {
      width: 200px;
      height: auto;
    }
  }
  .subheader {
    color: #fff;
    margin-top: -18px;
  }
  .icons {

    @include vHD {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    @include HD {
      justify-content: space-between;
    }

    .icon {
      width: 190px;
      margin: 0 auto 40px;

      @include vHD {
        margin: 0 10% 40px;
      }

      @include HD {
        width: 240px;
        margin: 20px 0 40px;
      }

      img {
        width: 100%;
        height: auto;
        display: block;

        @include HD {
          margin-bottom: 20px;
        }
      }
      
      .name {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 10px;
        color: #fff;
        background-color: #00aed6;
        text-align: center;
        font-weight: 700;
        height: 78px;
        font-size: .8em;

        @include HD {
          height: 100px;
        }
      }
    }
  }

  .points {
    margin-top: 60px;

    @include HD {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .point {
      position: relative;
      margin-bottom: 40px;

      @include HD {
        width: 46%;

        &:nth-child(2) {
          order: 3;
        }

        &:nth-child(3) {
          order: 5;
        }

        &:nth-child(4) {
          order: 2;
        }

        &:nth-child(5) {
          order: 4;
        }
      }

      .point-id {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 65px;
        line-height: 1;
        font-weight: 700;
        color: #00aed6;
      }

      p {
        padding-left: 60px;

        @include HD {
          margin-top: 0;
        }
      }
    }
  }
}

.velo-products {
  @include HD {
    padding-bottom: 5rem;
  }

  &__title {
    font-size: 37px;
    color: #041e42;

    @include HD {
      font-size: 80px;
    }
  }
  &__image {
    padding: 3rem 0;
    width: 100%;
  }
  &__image--photo {
      width: 100%;
      margin: 0 auto;
     
      @include HD {
        width: 1205px;
      }  

    img {
      display: block;
      width: 100%;
    }

      // display: block;
      // height: auto;
      // max-width: 300px;
      // margin: 0 auto;

      // @include vHD {
      //   max-width: 500px;
      // }

      // @include HD {
      //   max-width: 700px;
      // }  
  }
  &__image-text {
    margin-top: 60px!important;
    margin-bottom: 0!important;
    color: #00AED6;
    // &__blue-text {
    //   color: #00AED6;
    // }
  }
  &__disclaimer {
    font-size: 14px;
    color: #041e42;
    span {
      display: block;
    }

    @include HD {
      font-size: 16px;
    }
  }
}

.velo-product {
  &__list-item {
    list-style: none;
  }

  &__photo {
    position: relative;
    margin: 10px 0;
    padding-top: 1.25rem;

    @include vHD {
      margin: 30px 0;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    &--large {
      @include vHD {
        img {
          width: 100%;
        }
      }
    }
    &--small {
      @include vHD {
        img {
          width: 90%;
        }
      }
    }
    &--full {
      padding-top: 0;
      @include vHD {

        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  &__price {
    position: absolute;
    top: -15px;
    left: 0vw;
    display: block;
    background-color: #041e42;
    padding: 0.1rem 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(-25deg) skew(-25deg);

    @include vHD {
      left: 5vw;
      width: 100px;
      height: 25px;
      top: 0px;
    }

    @include HD {
      top: -10px;
      width: 200px;
      height: 50px;
    }
  }
  &__price--value {
    transform: skew(25deg);
    font-size: 14px;
    display: block;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    @include HD {
      font-size: 40px;
    }
  }

  &__desc {
    font-size: 19px;
    color: #041e42;

    @include HD {
      font-size: 24px;
      line-height: 30px;
    }

    @include UXGA {
      font-size: 30px;
      line-height: 35px;
    }
    @include HD {
      //max-width: 800px;
      //here
      text-align: left;
    }

    span {
      @include HD {
        display: block;
      }
    }
  }

  &__desc--left {
    text-align: left;
    max-width: 1100px;
  }

  &__special {
    display: block;
    font-weight: bold;
    &--ice {
      color: #006ac6;
    }
    &--mint {
      color: #009681;
    }
    &--freeze {
      color: #8a8d8f;
    }
    &--frost {
      color: #4d49be;
    }
  }

  &__disclaimer {
    font-size: 14px;
    color: #041e42;
    @include HD {
      font-size: 16px;
    }
  }

  &__category {
    font-size: 37px;
    word-break: break-word;
    margin-top: 2em !important;

    @include HD {
      font-size: 60px;
      line-height: 83px;
    }

    &--nospace {
      @include HD {
        margin-top: 0 !important;
      }
    }
  }

  &__list--container {
    @include HD {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .velo-product__desc {
      @include HD {
        text-align: left;
      }
    }
  }
  &__list--container > &__list-item {
    @include HD {
      display: flex;
      flex-direction: column;
      width: 45%;
    }
  }

  &__list-item--padding {
    @include HD {
      padding-top: 2.5rem;
    }
  }
}

.product-cards-section {
  padding: 1em 5%;
  background-color:#EFEFEF;

  h2 {
    font-size: 37px;
    margin-bottom: 1em;

    @include HD {
      font-size: 60px;
    }
  }

  .product-cards {

    @include vHD {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: .5em 0;
    }

    @include XGA {
      justify-content: center;
      flex-wrap: wrap;
    }

    .good-for-beginners {
      position: relative;

      .good-for-beginners-img {
        position: absolute;
        width: 97px;
        height: 97px;
        right: 0;
        top: 0;
      }
    }

    .product-card {
      padding: 20px 3%;
      margin: 1em 0;
      display: block;
      list-style: none;
      background-color: #fff;
      font-size: 18px;

      @include vHD {
        width: 49%;
      }

      @include XGA {
        width: 23%;
        margin: 1em 0.5%;
      }

      img {
        width: 80%;
        height: auto;
        margin: 0 auto;
        display: block;
        max-width: 250px;
      }

      p {
        margin-bottom: 0;
      }

      .product-title {
        font-weight: 700;
        font-size: 18px;
      }

      .description {
        font-size: 16px;
      }

      .info {
        font-size: .8em;
      }

      .strength {
        display: flex;
        margin-top: 10px;

        div {
          width: 12px;
          height: 12px;
          border: 1px solid #F13140;
          border-radius: 50%;
          margin-right: 8px;

          &.full {
            background-color: #F13140;
          }

        }

        &.mint {
          div {
            border: 1px solid #249181;

            &.full {
              background-color: #249181;
            }
          }
        }

        &.berry {
          div {
            border: 1px solid #464CBA;

            &.full {
              background-color: #464CBA;
            }
          }
        }

        &.darkred {
          div {
            border: 1px solid #C50A2C;

            &.full {
              background-color: #C50A2C;
            }
          }
        }

        &.yellow {
          div {
            border: 1px solid  #FFE800;

            &.full {
              background-color: #FFE800;
            }
          }
        }
      }
    }
  }

}

.velo-how {
  padding: 3rem 0;
  color: #fff;
  background: #041e42;

  &__title {
    font-size: 37px;
    text-transform: uppercase;
    color: #fff;

    span {
      display: block;
      color: #00aed6;
    }
    @include vHD {
      font-size: 68px;
    }
  }
}
.velo-how-tutorial {
  @include vHD {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__step {
    display: flex;
    padding: 0;

    @include vHD {
      width: 500px;
      justify-content: space-between;
    }
    @include HD {
      width: 100%;
      max-width: 900px;
    }

  }

  &__icon {
    position: relative;
    display: block;

    .icon {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 120px;

      @include vHD {
        width: 200px;
        height: 200px;
      }

      @include HD {
        width: 300px;
        height: 300px;
      }
    }

    img {
      height: auto;
      width: 120px;
    }

    .timer {
      width: 80px;
    }

    @include vHD {
      img {
        display: block;
        width: 200px;
      }

      .timer {
        width: 110px;
      }
    }

    @include HD {
      img {
        display: block;
        width: 300px;
      }

      .timer {
        width: 180px;
      }
    }
  }

  &__counter {
    position: absolute;
    top: 0;
    left: -10px;
    display: block;
    font-size: 16px;
    @include HD {
      font-size: 30px;
      left: -50px;
      top: 15%;
    }
  }

  &__desc:nth-child(even) {
    padding-left: 1.5rem;
    padding-right: 0;
    @include vHD {
      padding: 0 1rem;
    }
  }
  &__desc:nth-child(odd) {
    padding-right: 1.5rem;
    padding-left: 0;
    @include vHD {
      padding: 0 1rem;
    }
  }

  &__desc {
    font-size: 16px;
    margin: 0;
    padding: 0 1rem;

    span {
      @include vHD {
        display: block;
      }
    }

    @include vHD {
      max-width: 300px;
      align-self: center;
    }
    @include HD {
      font-size: 30px;
      max-width: 450px;
      line-height: 35px;
    }
  }
}

.velo-find {
  &__image--desktop {
    width: 100%;
    height: 100%;
    display: none;
        @include HD {
        display: block;
    } 
  }
  &__image--mobile {
    display: block;
    width: 100%;
    height: 100%;
    @include HD {
        display: none;
    }
  }

  &__title {
    font-size: 37px;
    text-align: right;
    color: #041e42;

    @include HD {
      font-size: 68px;
    }
    span {
      display: block;
      color: #00aed6;
    }
  }

  &__desc {
    font-size: 19px;
    color: #041e42;
    span {
      font-weight: bold;
    }
    @include HD {
      font-size: 30px;
      line-height: 35px;
    }
  }

  &__wrapper {
    @include HD {
      display: grid;
      margin-top: 2rem;
      grid-template-columns: 500px 1fr;
    }
  }

  &__logo {
    width: 120px;
    height: 34px;
    display: block;
    @include HD {
      height: 71px;
      width: 250px;
      max-width: 250px;
    }
  }

  &__btn {
    color: #fff;
    background: #4e559c;
    text-decoration: none;
    padding: 1rem;
    display: block;
    margin: 2rem auto;
    max-width: 200px;
    text-align: center;

    @include HD {
      align-self: center;
      max-width: 250px;
      width: 100%;
      margin: 0 0 0 auto;
    }
  }
}

.velo-find-map {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.5rem;

  &__desc {
    font-size: 19px;
    color: #041e42;
    span {
      font-weight: bold;
    }

    @include HD {
      font-size: 30px;
    }
  }

  &__image {
    display: block;
    align-self: center;
    margin-top: 2rem;

    @include HD {
      margin-top: 3rem;
    }
  }

  &__mobile {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 300px;

    @include HD {
      display: none;
    }
  }
  &__desktop {
    display: none;
    @include HD {
      display: block;
    }
  }
}
