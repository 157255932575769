@import "../../styles/breakpoints.scss";
@import "../../styles/vars.scss";

.ReactModal__Body--open {
  overflow: hidden;
}

.popup-inner {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;

  @include HD {
    display: flex;
    flex-flow: row;
  }
}

.modal-switch {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 311px;

  @include HD {
    margin-left: auto;
    min-width: 280px;
  }

  &__content {
    color: #fff;
    font-size: 16px;
    margin-right: auto;
  }

  .modal-switch-btn {
    width: 130px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 25px;
    position: relative;
    background: transparent;
    cursor: pointer;

    &__bullet {
      position: absolute;
      left: 2px;
      top: 50%;
      transform: translateY(-50%);
      height: 34px;
      width: 34px;
      background: #fff;
      display: block;
      border-radius: 50%;
      transition: transform 0.3s ease-in-out;
      background: url("../../assets/img/Modal/Uncheck.png");

      &--active {
        left: auto;
        transform: translate(90px, -50%);
        background: url("../../assets/img/Modal/Check.png");
      }
    }
  }
}

.modal-cookie-wrapper {
  position: relative;

  &__logo {
    display: none;

    @include HD {
      display: block;
      height: 143px;
      width: 125px;
      position: absolute;
      left: -18vw;
      bottom: 50%;
      transform: translateY(50%);
      width: 219px;
      height: 250px;
    }

    img {
      margin-top: 2rem;
      width: 219px;
      height: 250px;
    }
  }

  &::before,
  &::after {
    display: none;
    @include HD {
      position: absolute;
      content: "";
      display: block;
      background: url(../../assets/img/Modal/splash.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &::before {
    height: 55px;
    width: 55px;
    top: -8rem;
    right: -8rem;
  }
  &::after {
    height: 55px;
    width: 55px;
    bottom: -8rem;
    right: -8rem;
    transform: rotate(90deg);
  }
}
.modal-cookie-tabs {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 300px;
  position: relative;

  @include HD {
    display: flex;
    min-height: 450px;
    max-width: 880px;
  }

  &__body {
    background-color: #031e42;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include HD {
      padding-top: 100px;
      padding-left: 50px;
      padding-right: 50px;
      min-width: 650px;
    }
  }

  &__cta-wrapper {
    display: flex;
    flex-direction: column;

    @include HD {
      height: 100%;
      width: 180px;
      justify-content: space-evenly;
      align-content: center;
    }
  }
  &__tab-btn {
    color: #031e42;
    font-size: 20px;
    background-color: transparent;
    border: none;
    margin: 5px 0;
    cursor: pointer;

    @include HD {
      text-align: left;
      font-size: 18px;
      word-break: break-word;
    }

    &--active {
      font-weight: bold;
    }

    span {
      display: block;
    }
  }

  &__desc {
    font-size: 16px;
    text-align: center;
    color: #fff;
    @include HD {
      text-align: left;
      line-height: 24px;
    }
    &--to-HD {
      display: none;
      @include HD {
        display: block;
      }
    }
    &--to-Mobile {
      display: block;
      @include HD {
        display: block;
      }
    }
  }

  &__top {
    min-height: 100px;
    background: #fff;
    padding: 15px;
  }

  &__close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;
    border: none;
    background-color: transparent;

    &:before,
    &:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 18px;
      width: 1px;
      background-color: #031e42;
      cursor: pointer;

      @include HD {
        background-color: #fff;
      }
    }
    &:before {
      transform: rotate(45deg);
      top: 0;
    }
    &:after {
      transform: rotate(-45deg);
      top: 0;
    }
  }
}

.modal-cookie {
  position: relative;
  background: #001840;
  width: 100%;
  height: 100%;
  min-width: 300px;
  position: relative;
  padding: 20px;
  padding-top: 50px;
  @include HD {
    max-width: 880px;
    padding-left: 40px;
    padding-right: 40px;
  }

  &__link {
    text-decoration: none;
  }

  &__close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;
    border: none;
    background-color: transparent;

    &:before,
    &:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 18px;
      width: 1px;
      background-color: #fff;
      cursor: pointer;
    }
    &:before {
      transform: rotate(45deg);
      top: 0;
    }
    &:after {
      transform: rotate(-45deg);
      top: 0;
    }
  }

  &__paragraph {
    color: #fff;
    text-align: center;
    margin: 1rem 0;
    line-height: 20px;
    font-size: 14px;

    @include HD {
      margin: 1.25rem 0;
    }

    &--strong {
      font-weight: bold;
    }

    a {
      color: white;
    }
  }
  &__btn-group {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 9rem;
    justify-content: space-between;

    @include HD {
      flex-direction: row;
      justify-content: space-between;
      max-width: 55%;
      margin: 4rem auto 0;
      height: 6rem;
    }
  }

  &__br {
    @include HD {
      display: none;
    }
  }

  &__btn {
    background: #fff;
    color: #001840;
    font-weight: bold;
    border: none;
    border-radius: 2px;
    height: 4rem;
    cursor: pointer;
    font-size: 16px;
    transition: 0.2s ease-in-out;
    border: 1px solid transparent;

    @include HD {
      flex-direction: row;
      justify-content: center;
      min-width: 140px;
      font-size: 16px;
      padding: 1rem;
    }
  }

  &__btn:hover {
    background: #001840;
    color: #fff;
    border: 1px solid #fff;
  }
}

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 300ms $QuadEaseOut 0.1s, opacity 300ms $QuadEaseOut 0.1s;
  background: rgba(0, 0, 0, 0);
  outline: none;
  border: none;
  opacity: 0;
  margin-top: -30px;
  //need to comment for decor
  // overflow-x: hidden;
  max-width: 100%;

  &_after-open {
    opacity: 1;
    transform: translate(-50%, -43%);
  }
  &_before-close {
    opacity: 0;
  }

  //ageGate modal
  &__ageGate {
    background: #001840;
    width: 100%;
    height: 100%;
    min-width: 300px;
    position: relative;

    &::before,
    &::after {
      display: none;
      @include HD {
        position: absolute;
        content: "";
        display: block;
        background: url(../../assets/img/Modal/splash.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &::before {
      height: 55px;
      width: 55px;
      top: -8rem;
      right: -8rem;
    }
    &::after {
      height: 55px;
      width: 55px;
      bottom: -8rem;
      right: -8rem;
      transform: rotate(90deg);
    }

    @include HD {
      max-width: 880px;
      width: 880px;
    }

    h2 {
      color: #fff;
      text-align: center;

      @include HD {
        margin-top: 2em;;
      }
    }

    &__top {
      min-height: 150px;

      @include HD {
        min-height: auto;
      }

      img {
        margin-top: 2rem;
        height: 143px;
        width: 125px;

        @include HD {
          position: absolute;
          left: -18vw;
          bottom: 50%;
          transform: translateY(50%);
          width: 200px;
          height: 229px;
        }
      }
    }
    &__content,
    &__footer {
      padding: 2rem;
    }

    &__content br {
      display: none;

      @include HD {
        display: block;
      }
    }

    &__content p {
      color: #fff;
      font-size: 22px;
      text-align: center;
      padding-top: 1rem;

      @include HD {
        font-size: 30px;
        padding-top: 1.5rem;
      }

      span {
        display: block;

        @include HD {
          display: inline-block;
        }
      }
    }

    &__btn-group {
      margin-top: 3rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 9rem;
      justify-content: space-between;

      @include HD {
        flex-direction: row;
        justify-content: space-between;
        max-width: 55%;
        margin: 3rem auto 0;
      }
    }
    &__btn {
      background: #fff;
      color: #001840;
      text-transform: capitalize;
      font-weight: bold;
      border: none;
      border-radius: 2px;
      height: 4rem;
      cursor: pointer;
      font-size: 22px;
      transition: 0.2s ease-in-out;
      border: 1px solid transparent;

      @include HD {
        flex-direction: row;
        justify-content: center;
        min-width: 140px;
        font-size: 24px;
      }
    }

    &__btn:hover {
      background: #001840;
      color: #fff;
      border: 1px solid #fff;
    }
    &__footer {
      background: #fff;
      display: grid;
      grid-template-columns: 80px 1fr;
    }
    &__footer p {
      color: rgb(92, 91, 91);
    }

    &__logo {
      fill: #000;
      align-self: center;
      justify-self: center;
      width: 50px;
      height: auto;
    }

    &__disclaimer {
      font-size: 12px;
      color: #707070;

      span {
        @include HD {
          display: block;
        }
      }
    }

    &__error {
      display: block;
      padding-top: 2rem;
      color: rgb(218, 71, 71);
      font-size: 12px;
      text-align: center;
      @include HD {
        font-size: 14px;
      }
    }
  }
}

.overlay {
  z-index: 99000;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 40px;

  background: transparent;
  overflow-y: auto;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);

  &.noblur {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(125deg, #f3f3f311 50%, #3b3f43 100%);
    opacity: 0.65;
  }

  &_after-open {
    opacity: 1;
  }
  &_before-close {
    opacity: 0;
  }
}
