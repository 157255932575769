.faq-page {
  padding: 2em 0;

  .faq-description {
    margin: 1em 0;
    font-size: 21px;
    font-weight: 700;
    color: #686868;
  }

  .faq-questions {
    @include HD {
      margin-top: 50px;
    }
  }
}